import PropTypes from 'prop-types'
import React, { Component, useEffect } from "react";
import NextLink from 'next/link';
import Head from 'next/head';
import { withRouter } from 'next/router';
import { connect } from "react-redux";
import { logout, authCheckState, authClearErr } from "../store/actions/auth";
import { fetchUserProfile, profileClear } from "../store/actions/profile";
import {
  homePageURL,
  momentumchartPageURL,
  dividendchartPageURL,
  historicalPageURL,
  settingsPageURL,
  accountPageURL,
  loginPageURL,
  signupPageURL,
  passwordResetPageURL,
  subscribePageURL,
  cryptoPageURL,
  howtoPageURL,
  contactPageURL,
  termsPageURL,
  privacyPageURL,
  cookiePageURL,
  disclaimerPageURL,
  sitemapURL,
  refundPolicyURL,
} from './constants';
import { push } from './matomo/matomo-next';
import Navbar from "./Chakra/Navbar";
import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Stack,
  StackDivider,
  Text,
  useToast,
  useColorMode,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';


const CustomLayout = function CustomLayout(props) {

  // updateColorPref = (e) => {
  //   // console.log(e.matches)
  //   this.setState({ isDarkMode: e.matches })
  //   if (this.state.darkModeOverride == 'dark' || e.matches && this.state.darkModeOverride == 'follow') {
  //     document.body.style.backgroundColor = "#1C1C1C";
  //   } else {
  //     document.body.style.backgroundColor = "#f7f7f7";
  //   }

  // }

  const { colorMode, setColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'light') {
      document.body.style.backgroundColor = '#f7f7f7';
    } else {
      document.body.style.backgroundColor = '#1c1c1c';
    }
  }, [colorMode])

  useEffect(() => {
    // clear error messages from state
    props.authClear();
    props.profileClear();
    // fetch token from store
    props.authCheckState();
    if (props.authToken != null) {
      props.fetchUserProfile(props.authToken);
    }

    if (screen.width < 350) {
      var viewport = document.querySelector("meta[name=viewport]");
      viewport.parentNode.removeChild(viewport);

      var newViewport = document.createElement("meta");
      newViewport.setAttribute("name", "viewport");
      newViewport.setAttribute("content", "width=350, maximum-scale=1.0, user-scalable=no");
      document.head.appendChild(newViewport);
    }

    let title = 'Momentum Screener'
    switch (props.router.route) {
      case homePageURL: title += ' - Home'; break;
      case momentumchartPageURL: title += ' - Stock Table'; break;
      case cryptoPageURL: title += ' - Crypto'; break;
      case subscribePageURL: title += ' - Subscribe'; break;
      case howtoPageURL: title += ' - Help'; break;
      case contactPageURL: title += ' - Contact Us'; break;
      case loginPageURL: title += ' - Login'; break;
      case signupPageURL: title += ' - Sign Up'; break;
    }
    document.title = title

  }, [])

  useEffect(() => {
    if (props.authenticated) {
      props.fetchUserProfile(props.authToken)
    }
  }, [props.authenticated])


  // useEffect(() => {
  //   push(['setUserId', props.account]);
  // }, [props.account])
  function serverAvailabilityToast() {
    const toast = useToast()
    const id = 'test toast'
    if (!toast.isActive(id)) {
      return (
        toast({
          title: 'GOOD TOAST TITLE HERE @@@',
          description: 'Stock data was last processed on ' + 'UPDATE ME @@@',
          status: 'info',
          isClosable: true,
          duration: null,
          id
        })
      )
    }
  }
  // serverAvailabilityToast()

  const { authenticated, authToken, router, account } = props;

  return (
    <Box position={'absolute'} top={0} left={0} right={0}>
      <Head>
        <meta name="theme-color" content={props.router.route !== homePageURL ? useColorModeValue('#4299e1', '#3182ce') : useColorModeValue('#f7f7f7', '#1c1c1c')} />
      </Head>


      {/* <Flex id={'beta-banner'} color='white' bg='red.500' p={3} position={'relative'} left={0} right={0} zIndex={'docked'} justify={'center'}>
        <Text fontWeight={'bold'}>This web page is currently in beta. Bugs are expected and we encourage you to <NextLink href={contactPageURL} passHref><Link color='red.100'><u>report them</u></Link></NextLink> if you come across any.</Text>
      </Flex> */}

      {/* Header */}
      <Box position={'absolute'} left={0} right={0} zIndex={'dropdown'} bg={props.router.route !== homePageURL ? useColorModeValue('blue.400', 'blue.500') : undefined}>
        <Container maxW={'container.xl'}>
          <Navbar position={'relative'} isOpen={props.isOpen} onOpen={props.onOpen} onClose={props.onClose} signinRedirect={props.signinRedirect} />
        </Container>
      </Box>

      {/* Body */}
      {props.children}

      {/* Footer */}
      <Box background={useColorModeValue('blue.400', 'blue.500')}>
        <Container maxW={'container.xl'}>
          <Box p={{ base: '5rem 0 3rem 0', lg: '5rem 5rem 3rem 5rem' }}>
            <Stack
              direction={{ base: "column", lg: "row" }}
              color={'white'}
              align={'start'}
              spacing={'5rem'}
              divider={<StackDivider borderColor={'white'} />}
            >
              <Box flex={'1'}>
                <Heading pb={2} size='md'>About</Heading>
                <VStack align={'flex-start'} spacing={0}>
                  <NextLink href={sitemapURL} passHref><Link color={'blue.100'}>Site Map</Link></NextLink>
                  <NextLink href={contactPageURL} passHref><Link color={'blue.100'}>Contact Us</Link></NextLink>
                  <NextLink href={subscribePageURL} passHref><Link color={'blue.100'}>Subscribe</Link></NextLink>
                </VStack>
              </Box>
              <Box flex={'1'}>
                <Heading pb={2} size='md'>Legal</Heading>
                <VStack align={'flex-start'} spacing={0}>
                  <NextLink href={termsPageURL} passHref><Link color={'blue.100'}>Terms &amp; Conditions</Link></NextLink>
                  <NextLink href={privacyPageURL} passHref><Link color={'blue.100'}>Privacy Policy</Link></NextLink>
                  <NextLink href={cookiePageURL} passHref><Link color={'blue.100'}>Cookies Policy</Link></NextLink>
                  <NextLink href={refundPolicyURL} passHref><Link color={'blue.100'}>Refund Policy</Link></NextLink>
                </VStack>
              </Box>
              <Box flex={'3'}>
                <Heading pb={2} size='md'>Disclaimer</Heading>
                <Text>This site is intended for general information purposes only. There is no intent to provide specific advice or recommendations. See our full legal disclaimer <NextLink href={disclaimerPageURL} passHref><Link color={'blue.100'}>here</Link></NextLink>.</Text>
              </Box>
            </Stack>
            <Text
              color={'white'}
              pt={"2rem"}
            >
              Momentum Screener does not save any of your financial data. Your credit card will be processed through Stripe, our secure third-party payment processor.
            </Text>
            <Text
              color={'white'}
              pt={"2rem"}
            >© 2020-2022 Copyright Momentum Screener LLC</Text>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => {
  // console.log(state)
  return {
    authenticated: state.auth.token != null && state.auth.token != 'unauthenticated',
    authToken: state.auth.token,
    account: state.auth.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: (authToken) => dispatch(logout(authToken)),
    fetchUserProfile: (authToken) => dispatch(fetchUserProfile(authToken)),
    authCheckState: () => dispatch(authCheckState()),
    authClear: () => dispatch(authClearErr()),
    profileClear: () => dispatch(profileClear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomLayout));
