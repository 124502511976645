import axios from 'axios';
import { host } from './constants';

// const isServer = typeof window === "undefined";

// export const authAxios = isServer ?
//     axios.create(
//         {
//             baseURL: host,
//             headers: {
//                 Authorization: null
//             }
//         }
//     ) :
//     axios.create(
//         {
//             baseURL: host,
//             headers: {
//                 Authorization: `${localStorage.getItem('token')}`
//             }
//         }
//     );

export const authAxios = (token) => {
    return axios.create(
        {
            baseURL: host,
            headers: {
                Authorization: token != null && token != 'unauthenticated' ? `Token ${token}` : null
            }
        }
    )
}