import React, { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';
import NextLink from 'next/link';
import { passwordResetPageURL, signupPageURL } from "../constants";
import { connect } from "react-redux";
import { withRouter } from 'next/router';
import { login } from "../../store/actions/auth";



const SigninForm = function SigninForm(props) {

  const { error, loading } = props;

  function validate(values) {
    const errors = {};

    if (values.email.length > 0 && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required'
    } else if (values.password.length < 8) {
      errors.password = 'Must be at least 8 characters'
    }

    return errors;
  }

  return (
    // <Flex
    //     minH={'100vh'}
    //     align={'center'}
    //     justify={'center'}
    //     bg={useColorModeValue('gray.50', 'gray.800')}>
    //     <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
    //         <Stack align={'center'}>
    //             <Heading fontSize={'4xl'}>Sign in to your account</Heading>
    //             <Text fontSize={'lg'} color={'gray.600'}>
    //                 to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
    //             </Text>
    //         </Stack>
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={8}
      maxW={'lg'}
    >
      <Formik
        validate={validate}
        initialValues={{ email: '', password: '' }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          props.login(values.email, values.password);
          setSubmitting(false)
          // resetForm({
          //   email: values.email,
          //   subject: '',
          //   message: ''
          // })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={4}>
              <Field type='email' name='email'>
                {({ field, form }) => (
                  <FormControl isInvalid={(form.errors.email || (error && error.response && error.response.data.email)) && form.touched.email}>
                    <FormLabel htmlFor='email'>E-mail address</FormLabel>
                    <Input {...field} id="email" placeholder='E-mail address' ref={props.signinFocusRef} />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    {error && error.response && error.response.data.email && <FormErrorMessage>{error.response.data.email}</FormErrorMessage>}
                  </FormControl>
                )}
              </Field>
              <Field type='password' name='password'>
                {({ field, form }) => (
                  <FormControl isInvalid={(form.errors.password || (error && error.response && error.response.data.password)) && form.touched.password}>
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <Input {...field} id="password" type='password' placeholder='Password' />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    {error && error.response && error.response.data.password && <FormErrorMessage>{error.response.data.password}</FormErrorMessage>}
                  </FormControl>
                )}
              </Field>
            </Stack>
            <Stack justify={'space-around'} spacing={4}>
              <Stack
                direction={'row'}
                align={'space-around'}
                justify={'flex-end'}
                pt={2}
              >
                <NextLink href={passwordResetPageURL} passHref>
                  <Link color={'blue.400'}>Forgot password?</Link>
                </NextLink>
              </Stack>
              <Button
                id={'signin-button'}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                isLoading={isSubmitting || loading}
                type={'submit'}
              >
                Sign in
              </Button>
            </Stack>
          </Form>
        )
        }
      </Formik>
      <Stack spacing={4} alignItems={'center'}>
        <Text pt={'1rem'}>Don't have an account? &nbsp;
          <NextLink href={signupPageURL} passHref>
            <Link color={'blue.400'}>Sign up</Link>
          </NextLink>
        </Text>
      </Stack>
    </Box >
    //         </Stack>
    //     </Flex>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SigninForm));